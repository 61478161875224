/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wireframe': {
    width: 18,
    height: 15,
    viewBox: '0 0 18 15',
    data: '<g _stroke="#1D1D44" stroke-width=".842" _fill="none" fill-rule="evenodd"><path pid="0" d="M1 1h16v12.632H1z"/><path pid="1" d="M6.895 3.526h7.579v4.211H6.895z"/><path pid="2" stroke-linecap="square" d="M4.368 1.632v11.789m2.527-3.789h7.579m-7.579 1.684h7.579"/></g>'
  }
})
